/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.1.0-v202412101818-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqDatasourcesApiClass {
 
   constructor() {}

  /**
   * @summary Archive a datasource
   * @param {string} id - ID of the datasource to delete
   */
  public archiveDatasource(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/datasources/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Archive stale hosted items
   * @param {string} id - ID of the datasource to clean up
   */
  public cleanUp(
    body: models.DatasourceCleanUpInputV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/datasources/${encodeURIComponent(String(id))}/cleanup`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatasourceCleanUpOutputV1>;
  }

  /**
   * @summary Create a datasource
   */
  public createDatasource(
    body: models.DatasourceInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/datasources`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatasourceOutputV1>;
  }

  /**
   * @summary Disable all connections associated with a datasource
   * @param {string} id - ID of the datasource
   */
  public disableConnections(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/datasources/${encodeURIComponent(String(id))}/disable-connections`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ConnectionsOutputV1>;
  }

  /**
   * @summary Archive a datasource, its connections, and all items hosted by the datasource
   * @param {string} id - ID of the datasource to archive
   */
  public fullyArchiveDatasource(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/datasources/${encodeURIComponent(String(id))}/archive`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.JobAcceptedOutputV1>;
  }

  /**
   * @summary Get a Datasource
   * @param {string} id - ID of the datasource to retrieve
   */
  public getDatasource(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/datasources/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatasourceOutputV1>;
  }

  /**
   * @summary Get a collection of datasources
   * @param {string} [datasourceClass] - The datasource class, the name of the underlying source, &#x27;CSV File&#x27; for example, not specifying this parameter will return datasources of any class
   * @param {string} [datasourceId] - The datasource ID, an identifier that distinguishes a datasource from others of the same datasource class, &#x27;timeseries/salt&#x27; for example, not specifying this parameter will return datasources of any ID
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [includeArchived=false] - Whether archived datasources should be included
   * @param {string} [scopedTo] - The scope of the datasource
   * @param {boolean} [excludeGloballyScoped=false] - Whether globally scoped datasources should be excluded
   */
  public getDatasources(
    {
      datasourceClass,
      datasourceId,
      offset,
      limit,
      includeArchived,
      scopedTo,
      excludeGloballyScoped
    } : {
      datasourceClass?: string,
      datasourceId?: string,
      offset?: number,
      limit?: number,
      includeArchived?: boolean,
      scopedTo?: string,
      excludeGloballyScoped?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/datasources`,

      params: omitBy({
        ['datasourceClass']: datasourceClass,
        ['datasourceId']: datasourceId,
        ['offset']: offset,
        ['limit']: limit,
        ['includeArchived']: includeArchived,
        ['scopedTo']: scopedTo,
        ['excludeGloballyScoped']: excludeGloballyScoped
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatasourceOutputListV1>;
  }

  /**
   * @summary Get a list of the items hosted by this datasource
   * @param {string} id - ID of the datasource
   * @param {string} [dataId] - The dataId for a specific hosted item
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getItemsHostedByDatasource(
    {
      id,
      dataId,
      offset,
      limit
    } : {
      id: string,
      dataId?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/datasources/${encodeURIComponent(String(id))}/items`,

      params: omitBy({
        ['dataId']: dataId,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

  /**
   * @summary Get statistics describing the health of remote datasources
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getStatus(
    {
      offset,
      limit
    } : {
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/datasources/status`,

      params: omitBy({
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatasourceStatusOutputV1>;
  }

  /**
   * @summary Recount item totals for a datasource. Do not run while datasource is indexing
   * @param {string} id - ID of the datasource to recount
   */
  public recount(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/datasources/${encodeURIComponent(String(id))}/recount`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.DatasourceOutputV1>;
  }

}


export const sqDatasourcesApi = new sqDatasourcesApiClass();
